define(['cookie'], function(cookie) {

  const oneTrustHelper = () => {

    const component = {};
    component.init = () => {
      window.addEventListener('load', component.addClickListenersToAcceptButtons, false);
    };

    component.addReloadPageListener = (element) => {
      element.addEventListener('click', component.refreshPage);
    };

    component.refreshPage = () => {
      location.reload();
    };

    component.addClickListenersToAcceptButtons = () => {

      let OABCcookieName = "OptanonAlertBoxClosed";
      let bannerAcceptBtn = document.getElementById("onetrust-accept-btn-handler");
      let pcAllowAllBtn = document.getElementById("accept-recommended-btn-handler");
      let pcSaveBtn = document.getElementsByClassName("save-preference-btn-handler onetrust-close-btn-handler")[0];
      let OABCcookie = cookie.get(OABCcookieName);

      if (!OABCcookie && bannerAcceptBtn) {
        component.addReloadPageListener(bannerAcceptBtn);
      }

      if (pcAllowAllBtn) {
        component.addReloadPageListener(pcAllowAllBtn);
      }

      if (pcSaveBtn) {
        component.addReloadPageListener(pcSaveBtn);
      }
    };

    return component;
  };

  return oneTrustHelper;
});
